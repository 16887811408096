import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Sound Accessibility'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sound Accessibility`}</h1>
    <p>{`A web page with interface sounds effects should not be intrusive to the
user. Certain Arwes applications may make the user expect sounds effects implicitely,
but this is not necessarily the default case. See the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`UI/UX guidelines`}</a>{`
where some recommendations are made.`}</p>
    <p>{`Since some browsers have `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/updates/2018/11/web-audio-autoplay"
      }}>{`Audio Autoplay Policies`}</a>{`,
they block the sound playback on window load until first user interaction.
It is not recommended to play sounds on window start time. Only after first
user interaction.`}</p>
    <p>{`Arwes sounds tools will not play sounds until the browser audio is unlocked.
They will not throw errors nor show warning messages.`}</p>
    <p>{`If the application requires to play sounds as normal user experience, it is
recommended to "explicitely" request permission from the user, such as showing
a start page with a button to request user interaction before access
to the application.`}</p>
    <p>{`The sounds tools allow the programmer to define if the sounds are enabled,
the sound volume, and the rate of playback. It also allows to set settings
by `}<a parentName="p" {...{
        "href": "/develop/sounds/categories"
      }}>{`categories of sounds`}</a>{`. It is recommended to show
the user a settings panel where to configure these behaviors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      